import { render, staticRenderFns } from "./ModalEnvioDocumentos.vue?vue&type=template&id=06095ea2&scoped=true"
import script from "./ModalEnvioDocumentos.vue?vue&type=script&lang=js"
export * from "./ModalEnvioDocumentos.vue?vue&type=script&lang=js"
import style0 from "./ModalEnvioDocumentos.vue?vue&type=style&index=0&id=06095ea2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06095ea2",
  null
  
)

export default component.exports